<template>
  <v-app-bar color="primary" dark app>
    <v-app-bar-nav-icon @click="handleDrawerToggle" />
    <v-spacer />
    <v-toolbar-items>
      <v-menu
        offset-y
        origin="center center"
        class="elelvation-1"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn text slot="activator" v-on="on">
            <v-icon medium>mdi-translate</v-icon>
            <span class="ml-2"> {{ localeText }} </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.lang.current">
            <v-list-item
              @click="handleChangeLocale(item)"
              v-for="item in availableLanguages"
              :key="item.value"
              :value="item.value"
            >
              <v-list-item-title v-text="item.text" />
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu offset-y origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon large text slot="activator" v-on="on">
            <v-avatar size="30px">
              <img v-if="currentUser" :src="currentUser.avatar" :alt="currentUser.username" />
            </v-avatar>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in profileMenus"
            :to="!item.href ? { name: item.name } : null"
            :href="item.href"
            @click="item.click"
            ripple="ripple"
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            :key="index"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-toolbar tag="div" dense slot="extension" color="white" light>
      <v-breadcrumbs :items="breadcrumbs" class="pa-3" />
      <v-spacer></v-spacer>
      <v-btn icon small color="black">
        <v-icon v-text="'mdi-arrow-left'" @click="handleGoBack" />
      </v-btn>
    </v-toolbar>
  </v-app-bar>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppToolbar',
  components: {},
  data() {
    return {
      profileMenus: [
        {
          icon: 'mdi-lock-reset',
          href: '#',
          title: this.$vuetify.lang.t('$vuetify.profileMenus.resetPassword'),
          click: this.handleResetPassword
        },
        {
          icon: 'mdi-power',
          href: '#',
          title: this.$vuetify.lang.t('$vuetify.profileMenus.logout'),
          click: this.handleLogut
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/user',
    }),
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    availableLanguages() {
      const { locales } = this.$vuetify.lang;
      return Object.keys(locales).map((lang) => {
        return {
          text: locales[lang].label,
          value: lang
        };
      });
    },
    localeText() {
      const find = this.availableLanguages.find(
        (item) => item.value === this.$vuetify.lang.current
      );
      return find.text;
    },
    breadcrumbs() {
      const { matched } = this.$route;
      return matched.map((route, index) => {
        const to =
          index === matched.length - 1
            ? this.$route.path
            : route.path || route.redirect;
        const text = route.name === 'default' ? 'default' : this.$vuetify.lang.t('$vuetify.route.' + route.name);
        return {
          text: text,
          to: to,
          exact: true,
          disabled: false
        };
      }).filter( element => element.text !== 'default');
    }
  },
  methods: {
    handleDrawerToggle() {
      this.$emit('side-icon-click');
    },
    handleFullScreen() {},
    handleLogut() {
      if (window.confirm('Are you sure to logout?')) {
        this.$store.dispatch('auth/logout');
      }
    },
    handleChangeLocale({ value }) {
      this.$vuetify.lang.current = value;
    },
    handleResetPassword () {
      this.$router.push({ name: 'resetPassword' })
    },
    handleSetting() {},
    handleProfile() {},
    handleGoBack() {
      this.$router.go(-1);
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__extension {
  padding: 0 !important;
}
</style>
